<template>
    <div class="enterhistory">
        <div class="title">
            <span class="title-text">
                تاریخچه ورود
            </span>
            <div class="goto">
                <span class="clickable"> تنظیمات امنیتی </span>
                <inline-svg class="arrowsvg" :src="require('../../../assets/icons/inputarrow.svg')" />
            </div>
        </div>


        <div class="history-container">
            <div v-for="(item,index) in loginData" :key="index" class="history">
                <div class="row">
                    <div class="column">
                        <span> {{item.browser}} </span>
                        <span> {{item.ipAddress}} </span>
                    </div>
                    <div class="column">
                        <span> {{item.deviceType}} </span>
                        <span> {{item.osType}} </span>
                    </div>
                </div>
                <div class="row">
                    <span> {{item.ipAddress}} </span>
                    <span> {{$G2J(item.loginRequestedDateTime)}} </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'EnterHistory',
        data() {
            return {
                loginData:[
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                ],
            }
        },
    }
</script>

<style lang="scss" scoped>
.history-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    color: var(--grey);
    .history{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #cacacead;
        padding-bottom: 5px;
    }
    .row{
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        justify-content: space-between;
        .column{
            display: flex;
            flex-direction: row;
            column-gap: 4px;
        }
    }
}
.enterhistory{
    height: 100%;
    border-radius: 12px;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    row-gap: 12px;
    background: white;
    min-height: 200px;
}
.title{
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #cacacead;
}
.title-text{
    color: var(--black-fee);
    word-spacing: 4px;
}
.arrowsvg{
    transform: rotate(90deg);
}
.clickable{
    color: var(--dark-primary);
    cursor: pointer;
}
@media only screen and(max-width:1700px){
    .enterhistory{
        max-height: 100px;
    }
}
@media only screen and(max-width:1200px){
    .title{
        height: 20% !important;
    }
}
</style>