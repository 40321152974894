<template>
    <div class="slider-contaienr">
        <div v-for="(item,index) in market" :key="index" class="coin-info">
            <div class="coin-label">
                <img class="coin-img" :src="'/coins/'+$coin2Snake[item.relatedCoin]+'.png'" alt="">
                <TrendChart class="remove2" :data="item.lastWeekData" />
                <span class="coinunit"> {{item.relatedCoin}} </span>
            </div>
            <span> {{$coinLabel[item.relatedCoin]}} </span>
            <div class="pricetag">
                <span class="price"> {{$toLocal(item.lastPrice,0)}} </span>
                <span class="pricetype"> تومان </span>
            </div>
            <div class="changes">
                            <span v-if="item.last24HourChange>0" class="success"> {{$toLocal(item.last24HourChange,2)}}% </span>
                            <span v-if="item.last24HourChange<=0" class="fail"> {{ $toLocal(Math.abs(item.last24HourChange),2) }}% </span>
                            <inline-svg :class="item.last24HourChange > 0 ?'success' :'fail'" :src="require('../../../assets/icons/changes.svg')" />
            </div>
        </div>
    </div>
</template>

<script>
import TrendChart from '../../Tools/TrendChart.vue'
export default {
    name:'MarketSlider',
    components:{
        TrendChart,
    },
    data(){
        return{
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                  '&market_type=BITCOIN_CASH_TOMAN' +
                  '&market_type=ETHEREUM_TOMAN' +
                  '&market_type=LITE_COIN_TOMAN' +
                  '&market_type=BINANCE_COIN_BSC_TOMAN' +
                  
                  '&market_type=STELLAR_TOMAN' +
                  '&market_type=TRON_TOMAN' +
                  '&market_type=CELO_TOMAN' +
                  '&market_type=RIPPLE_TOMAN' +
                  '&market_type=DOGE_COIN_TOMAN' +
                  '&market_type=MATIC_TOMAN' +
                  '&market_type=TETHER_TOMAN',



              TetherCoin: 'market_type=BITCOIN_TETHER' +
                  '&market_type=BITCOIN_CASH_TETHER' +
                  '&market_type=ETHEREUM_TETHER' +
                  '&market_type=LITE_COIN_TETHER' +
                  '&market_type=BINANCE_COIN_BSC_TETHER' +
                  '&market_type=MATIC_TETHER' +

                  '&market_type=STELLAR_TETHER' +
                  '&market_type=TRON_TETHER' +
                  '&market_type=RIPPLE_TETHER' +
                  '&market_type=DOGE_COIN_TETHER' +
                  '&market_type=CELO_TETHER', 




            changRange:'weekly',
            toman:true,
            highestLastPrice:null,
            highestChanges:null,
            highestVolume:null,
            market:[],
            series:[{
                name: "series-1",
                data: [30, 40, 35, 50, 49, 60, 70, 91],
            }],
            chartOptions:{
                chart: {
                    id: "vuechart-example",
                    },
                    xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                    },
            },
        }
    },
    methods:{
            async lastTrades(){
                const res  = await this.$axios.get('/trades/market-info?'+(this.toman ? this.TomanCoin : this.TetherCoin),{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.market=res.content 
                    if(!this.toman){
                        this.market=this.market.filter(a => a.relatedCoin !== 'USDT')
                    }   
            },
    },
    mounted(){
        this.lastTrades();
    }
}
</script>

<style lang="scss" scoped>
.slider-contaienr{
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    border-radius: 12px;
    padding: 12px;
    background: var(--slider-back);
}
.coin-info{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    .coin-label{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 10px;
        .coin-img{
            width: 30px;
            height: 30px;
        }
        .coinunit{
            align-self: flex-end;
        }
    }
    .pricetag{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        .pricetype{
        color: var(--grey);
        }
    }
    .changes{
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;
    }
}
</style>