<template>
    <div class="card-container">
        <div class="warning">
            <h3> ali***@gmail.com </h3>
            <p class="warn"> حساب کاربری شما تایید نشده است برای استفاده از امکانات صرافی و معامله باید فرآیند احراز هویت را انجام دهید </p>
        </div>
        <div class="btns">
            <button class="submit"> احراز هویت کنید </button>
            <button class="guidebtn"> راهنمای احراز هویت </button>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.card-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border-radius: 12px;
    background: url('../../../assets/pannelcardbackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30%;
    height: 100%;
    min-height: 350px;
}
.warning{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.btns{
    display: flex;
    flex-direction: row;
    .guidebtn{
        background: none !important;
        color: var(--dark-primary);
    }
    button{
        width: 50%;
    }
}
.warn{
    font-weight: 400;
    font-size: clamp(12px,1vw,14px);
}
@media only screen and(max-width:400px){
    .btns{
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
        button{
            width: 100%;
        }
    }
}
</style>